import React, { useEffect, useState,useRef } from 'react';
import QRCode from 'qrcode.react';
import CircularProgress from '@material-ui/core/CircularProgress';

import socketIOClient from 'socket.io-client';

const SOCKET_SERVER_URL = "http://localhost:9000";
const QR_EVENT = "QREvent";
const WCLIENT_EVENT="WClientEvent";
export default function QR(){
    const[lastQR,setLastQR]=useState("");
    const [ready,setReady]=useState(false);
    const [authenticated,setAuthenticated]=useState(false);
    const[currentUser,setCurrentUser]=useState({});
    const socketRef = useRef();

    useEffect(()=>{
        const socket = socketIOClient(SOCKET_SERVER_URL);

        socket.on(QR_EVENT, data => {
            
          setLastQR(data);
        });

        socket.on(WCLIENT_EVENT,data=>{
            if(data=='AUTHENTICATED'){
                setAuthenticated(true);
                getCurrentUser();
            }
        });

        callAPI();

    },[]);
 const    callAPI=()=>{
        fetch("http://localhost:9000/state")
        
        .then(res => 
            res.text()
        )
        .then(res => {
            if(res=='CONNECTED'){
                setAuthenticated(true);
                setTimeout(()=>{
                    getCurrentUser();


                },3000)

            }
        });
    }

    const getCurrentUser=()=>{
        fetch("http://localhost:9000/user")
        
        .then(res => 
            res.text()
        )
        .then(res => {
            if(res!=null){
                setCurrentUser(res);

                console.log('current user => : '+currentUser.pushname);
            }
        });

    }
    return (<div className="container p-4">
       
        {authenticated?
        <span severity="success"> This Client had been authenticated from before .
         Current User is : <b>{currentUser?.pushname}</b>
        </span> :( lastQR && !authenticated) ?
        <div> <p>Getting the Qr Code</p>
       <QRCode size="264" o value={lastQR} /></div>
       :<div ><p>Getting the Qr Code</p><CircularProgress disableShrink /></div>
             }
         {
    }  </div>);
}

/* class QR extends React.Component{

    constructor(props){
        const {events ,setEvents}=useConnection(1);

        super(props);
        this.state={
            qr:""
        }
        this.callAPI=this.callAPI.bind(this);

    }
    componentDidMount(){
        this.callAPI();
    }
    callAPI(){
        fetch("http://localhost:9000/qr")
        
        .then(res => 
            res.text()
        )
        .then(res => this.setState({ qr: res }));
    }
    render(){
        return (<div>
            <p>Getting the Qr Code</p>
            
             {this.state.qr?
            <QRCode size="264" o value={this.state.qr} />
            :<CircularProgress disableShrink />
        }  </div>);
    }
}

export default QR; */